<template>
  <div>
    <Header></Header>
    <div class="jsfn1">
      <img class="jsfnA1" src="@/assets/gywm.png" alt="" />
      <div class="jsfnB1">关于然茜</div>
      <div class="jsfnC1">ABOUT RANCII</div>
    </div>
    <div class="body">
      <div class="title">荣誉代表过去的历程，未来还在路上</div>
      <div class="littleTitle">历程的里程碑，亦是鼓励，更是动力</div>
      <div class="hr"></div>
      <div class="company">公司荣誉</div>
      <div class="comEnglish">COMPANY HONOR</div>
      <div>
        <div class="ryA">
          <div v-for="(item,index) in corporateHonorsList" :key="index">
            <img @click="onPreview(item.limpidSrc)" class="ryAimg" :src="item.limpidSrc" alt="">
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="srcList" />
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { getCorporateHonorsList } from "../../api/open";
export default {
  components: {
    Header,
    Footer,
    ElImageViewer
  },
  data() {
    return {
      ryList:[],
      srcList:[],
      showViewer:false,
      corporateHonorsList: []
    };
  },
  mounted() {
    getCorporateHonorsList({}).then(res=>{
      this.corporateHonorsList = res.rows
      if(this.corporateHonorsList){
        this.corporateHonorsList.forEach(item=>{
          item.limpidSrc = process.env.VUE_APP_BASE_API+item.limpidSrc
        })
      }
    })

    let title = "";
    for(let i=1;i<11;i++){
      if(i===1){
        title="高新技术企业证书"
      }else if(i===2){
        title="企业信用等级证书"
      }else if(i===3){
        title="企业信用等级证书"
      }else if(i===4){
        title="重合同守信用企业"
      }else if(i===5){
        title="诚信经营示范单位"
      }else if(i===6){
        title="企业信用等级证书"
      }else if(i===7){
        title="质量服务诚信单位"
      }else if(i===8){
        title="诚信经营示范单位"
      }else if(i===9){
        title="企业资信等级证书"
      }else if(i===10){
        title="企业资信等级证书"
      }
      this.ryList.push(
        {
          url:process.env.VUE_APP_BASE_API+"/profile/upload/2023/01/06/ry"+i+".png",
          title:title,
        }
      )
    }
  },
  methods: {
    closeViewer() {
      this.showViewer = false
    },
    onPreview(url) {
      this.srcList=[]
      this.srcList.push(url)
      this.showViewer = true
    },
    goMore() {
      this.$router.push({ name: 'honor' })
    }
  },
};
</script>

<style scoped>
.c-title{
  margin: 0 auto;
  width: 228px;
  height: 34px;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #183F85;
  line-height: 25px;
  text-shadow: 0px 2px 4px rgba(0,0,0,0.2);
}
.view{
  color: white;
  margin: 20px auto;
  text-align: center;
  line-height: 40px;
  width: 160px;
  height: 40px;
  background: linear-gradient(90deg, #3862AE 0%, #94A7D0 100%);
  border-radius: 28px;
}

.el-carousel__item:nth-child(2n) {
  background-color: #FFFFFF ;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #FFFFFF;
}
.company{
  width: 103px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #183F85;
  line-height: 33px;
  letter-spacing: 1px;
}
.comEnglish{
  width: 242px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #BFBFBF;
  line-height: 33px;
  letter-spacing: 1px;
}
.hr{
  margin: 0 auto;
  width: 164px;
  height: 8px;
  background: linear-gradient(90deg, #183F85 0%, #94A7D0 100%);
  border-radius: 4px;
  margin-top: 16px;
}
.title{
  margin-top: 85px;
  width: 100%;
  text-align: center;
  font-size: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 56px;
  letter-spacing: 2px;
}
.littleTitle{
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4A4A4A;
  line-height: 28px;
  letter-spacing: 1px;
  margin-top: 16px;
}
* {
  margin: 0;
  padding: 0;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.jsfn1 {
  position: relative;
}
.jsfnA1 {
  width: 100%;
}
.jsfnB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
.ryA {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.ryAimg {
  /* width: 100%; */
  height:185px;
  margin: 16px;
}
</style>
